/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    var toautoplay = true;
    // toautoplay = false;

    // var url = document.URL;
    // var substring = 'localhost';
    // if (contains(url.toLowerCase(), substring.toLowerCase())) { 
    //     toautoplay = false;
    // }

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                $('#header-menu-nav').click(function() {
                    event.preventDefault();
                    $('.menu-pop-up').fadeIn(1000);
                    // console.log('menu button clicked');
                });
                $('#close-Menu').click(function() {
                    // console.log('close button clicked');
                    $('.menu-pop-up').fadeOut(1000);
                });
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
                $('.hp-full-carousel').owlCarousel({
                    loop: true,
                    nav: false,
                    dots: true,
                    items: 1,
                    autoplay: toautoplay,
                    autoplayTimeout: 5000,
                    autoplaySpeed: 1000,
                    autoplayHoverPause: true,
                });
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        'post_type_archive_platform_filmography': {
            init: function() {
                // console.log('filmography');
                $('.filmography-header').click(function(event) {
                    event.preventDefault();
                    // console.log('fh clicked clicked');
                    var target_d = $(this).data("target");
                    var target_id = '#fb-' + target_d;
                    $('.filmography-blurb').not(target_id).slideUp();
                    $(target_id).slideToggle();
                });

            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        },
        'page_template_template_contact': {
            init: function() {
                // DG - http://stackoverflow.com/questions/24768772/prevent-a-google-maps-iframe-from-capturing-the-mouses-scrolling-wheel-behavior
                // stop gmaps stealing mouse until click

                $('#map-canvas').addClass('scrolloff'); // set the pointer events to none on doc ready
                $('.map-wrapper').on('click', function() {
                    $('#map-canvas').removeClass('scrolloff'); // set the pointer events true on click
                });

                // you want to disable pointer events when the mouse leave the canvas area;
                $("#map-canvas").mouseleave(function() {
                    $('#map-canvas').addClass('scrolloff'); // set the pointer events to none when mouse leaves the map area
                });


                var loc1lat = $("#loc1lat").text();
                var loc1lng = $("#loc1lng").text();
                var map;
                var locations = [
                    ['Location 1', loc1lat, loc1lng],
                ];

                function initialize() {
                    var mapOptions = {
                        center: {
                            lat: parseFloat(loc1lat),
                            lng: parseFloat(loc1lng)
                                //   lat: 51.513413,
                                // lng: -0.126
                        },
                        // 51.513413,-0.126
                        zoom: 16
                    };
                    map = new google.maps.Map(document.getElementById('map-canvas'),
                        mapOptions);

                    for (i = 0; i < locations.length; i++) {
                        marker = new google.maps.Marker({
                            position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                            title: locations[i][0],
                            map: map
                        });
                    }



                }
                google.maps.event.addDomListener(window, 'load', initialize);
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
